import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { PhotoSwipeGallery } from 'react-photoswipe'
import { MapPin } from 'react-feather'
import Button from '../components/button'

const PhotoGallerySection = styled.section`
  img {
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    border-radius: 2px;
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 12px -2px;
    }
  }
  .pswp-thumbnails {
    display: flex;
    flex-wrap: wrap;
  }
  .pswp-thumbnail {
    flex: 0 0 25%;
    padding: 2%;
    @media (max-width: 992px) {
      flex: 0 0 33%;
    }
    @media (max-width: 767px) {
      flex: 0 0 50%;
    }
  }
`

const HeadingArea = styled.div`
  background: #ececec;
  text-align: center;
  padding: 50px 10px;
  padding-bottom: 100px;
  h1 {
    margin-bottom: 0.25em;
    font-size: calc(1vw + 1.35rem);
  }
  p {
    max-width: 767px;

    color: #666;
    margin: 0 auto;
    margin-bottom: 0.5em;
  }
`

class PhotoGalleryComponent extends Component {
  render() {
    console.log(this.props)
    const data = this.props.data
    let items = []

    console.log(data.contentfulPhotoGallery)

    {
      data.contentfulPhotoGallery.photos.map((item, i) => {
        let tempItem = {
          id: item.id,
          title: item.title,
          src: item.fluid.src,
          thumbnail: item.fixed.src,
          w: 1200,
          h: 900,
        }

        items.push(tempItem)
      })
    }

    let options = {}

    const getThumbnailContent = item => {
      return <img src={item.thumbnail} alt={item.title} />
    }

    return (
      <>
        <Layout>
          <SEO title="Landscaping Sacramento Photo Gallery" />
          <HeadingArea>
            <div className="container">
              <h1>{data.contentfulPhotoGallery.title}</h1>
              <p>{data.contentfulPhotoGallery.description.description}</p>
            </div>
          </HeadingArea>
          <div className="container" style={{ marginTop: '-70px' }}>
            <PhotoGallerySection>
              <PhotoSwipeGallery
                items={items}
                options={options}
                thumbnailContent={getThumbnailContent}
              />
            </PhotoGallerySection>
          </div>
        </Layout>
      </>
    )
  }
}

export default PhotoGalleryComponent

export const galleryQuery = graphql`
  query galleryQuery($slug: String!) {
    contentfulPhotoGallery(slug: { eq: $slug }) {
      id
      title
      slug
      description {
        description
      }
      photos {
        id
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
        fixed(width: 500, height: 500) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`
